// Modules
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Components
import ButtonContainer from "../components/buttonContainer/ButtonContainer";
import Header from "../components/header/HeaderContainer";
import Footer from "../components/footer/Footer";

// Styles
import styles from "../styles/careers.module.scss";

// Assets
import {
  famWork1Image,
  famWork2Image,
  famWork3Image,
  famWork4Image,
  famPerk2Image,
  famPerk3Image,
  famPerk4Image,
  famPerk5Image,
  memory1Image,
  memory2Image,
  memory3Image,
  memory4Image,
  memory5Image,
  memory6Image,
  careersPCHeaderImage,
  careersMobHeaderImage,
  memory7Image,
} from "../utils/imageProvider";
import {
  instagramColorIcon,
  twitterColorIcon,
  linkedinColorIcon,
} from "../utils/iconProvider";
import { Media } from "../utils/mediaQueryHandler";

const famValues = [
  {
    icon: "⭐️",
    title: "Be a student",
    subtitle:
      "Stay curious and don’t be afraid to ask questions. After all, the learning never stops.",
  },
  {
    icon: "💪",
    title: "Be entrepreneurial",
    subtitle:
      "Step out of your comfort zone by taking risks and being observant, resourceful, and fearless.",
  },
  {
    icon: "👑",
    title: "Be humble and grateful",
    subtitle:
      "Stay kind, grounded, accepting, and respectful to all. There’s no room for ego here.",
  },
  {
    icon: "💪",
    title: "Be vocal",
    subtitle:
      "Share your opinions, perspectives, and beliefs. If you’ve got something to say, we’re all ears.",
  },
  {
    icon: "👑",
    title: "Be self-governed",
    subtitle:
      "Build values and principles for yourself to stay self-aware and know what’s right and wrong.",
  },
  {
    icon: "🤝",
    title: "Be collaborative",
    subtitle:
      "Always share, care, play, fail, and celebrate together. Team work makes the dream work.",
  },
];

const famWorkFeatures = [
  {
    icon: famWork1Image,
    title: (
      <>
        Young and <br /> ridiculously friendly
      </>
    ),
    subtitle:
      "We're a thoughtful, curious, and collaborative bunch, always hungry for more",
  },
  {
    icon: famWork2Image,
    title: (
      <>
        A family that looks <br /> after each other
      </>
    ),
    subtitle: `We even call ourselves "the fam", and we live upto it`,
  },
  {
    icon: famWork3Image,
    title: (
      <>
        Working with the <br /> smartest generation
      </>
    ),
    subtitle:
      "Imagine solving the problems of our future leaders. Pretty cool, right?",
  },
  {
    icon: famWork4Image,
    title: (
      <>
        Every idea <br /> shines bright here
      </>
    ),
    subtitle:
      "From interns to full-timers, all ideas are considered an asset in our team ",
  },
];

const Perks = [
  {
    img: famPerk2Image,
    title: "Create your own flexible work schedule",
  },
  {
    img: famPerk3Image,
    title: "Top notch gadgets for top notch people",
  },
  {
    img: famPerk4Image,
    title: "ESOPS for all, coz we like to share",
  },
  {
    img: famPerk5Image,
    title: "Health insurance, for you & your loved ones",
  },
  {
    img: famPerk5Image,
    title: "Free therapy with mental health professionals",
  },
];

const Gallery = [
  {
    img: memory7Image,
    date: "18 Sep 21",
  },
  {
    img: memory1Image,
    date: "12 March 21",
  },
  {
    img: memory2Image,
    date: "16 March 21",
  },
  {
    img: memory3Image,
    date: "16 March 21",
  },
  {
    img: memory4Image,
    date: "16 May 20",
  },
  {
    img: memory5Image,
    date: "4 Jan 21",
  },
  {
    img: memory6Image,
    date: "16 March 21",
  },
];

const Careers = () => {
  return (
    <>
      <Container fluid>
        <Header />
        <Media lessThan="lg">
          <Row
            className={`header_section_background`}
            style={{ backgroundImage: `url(${careersMobHeaderImage})` }}
          >
            <Col>
              <div
                className={`heading_text white_text ${styles.header_heading}`}
              >
                <p className={`${styles.hashtag_text} subheading_text`}>
                  #JoinTheFam
                </p>
                <h1>
                  Be a part of the team setting the bar for{" "}
                  <div style={{ display: "inline-block" }}>new-world</div> work
                  culture
                </h1>
                <ButtonContainer
                  buttonText={"View openings"}
                  path="jobs"
                  wrapStyle={{
                    padding: "0 1.5rem",
                    backgroundColor: "#FBAF03",
                    fontSize: "16px",
                    lineHeight: "3",
                    fontWeight: "600",
                  }}
                  activeStatus
                />
              </div>
            </Col>
          </Row>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Row
            className={`header_section_background`}
            style={{
              backgroundImage: `url(${careersPCHeaderImage})`,
            }}
          >
            <Col>
              <div
                className={`heading_text white_text ${styles.header_heading}`}
              >
                <p className={`${styles.hashtag_text} subheading_text`}>
                  #JoinTheFam
                </p>
                <h1>
                  Be a part of the team setting the bar for{" "}
                  <div style={{ display: "inline-block" }}>new-world</div> work
                  culture
                </h1>
                <ButtonContainer
                  buttonText={"View openings"}
                  path="jobs"
                  wrapStyle={{
                    padding: "0 1.5rem",
                    backgroundColor: "#FBAF03",
                    fontSize: "16px",
                    lineHeight: "3",
                    fontWeight: "600",
                  }}
                  activeStatus
                />
              </div>
            </Col>
          </Row>
        </Media>
      </Container>
      <Container fluid className="below_header_container_dark">
        <Container className="extra_sides_padding">
          <Row className={`section_vertical_spacing`}>
            <Col className={`${styles.what_section}`}>
              <div className={`${styles.what_section_text}`}>
                <h1 className="heading_text primary_orange_text">
                  So like, what does Fam do?
                </h1>
                <p className="paragraph_text light_grey_text">
                  We are raising a new, financially aware generation of Indians
                  who understand the fundamentals of personal finance and are
                  capable of making decisions in their best long term interest.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Row
          className={`full_width_section ${styles.values_section} light_grey_text`}
        >
          <Col>
            <Row className="text-center">
              <Col>
                <h1 className="heading_text primary_orange_text">
                  The values we live for
                </h1>
              </Col>
            </Row>
            <Row>
              {famValues.map((value, index) => (
                <Col md={6} key={index}>
                  <Row className={`${styles.values_card}`}>
                    <Col sm={12} xl={2} className={`${styles.icon_container}`}>
                      <div>{value.icon}</div>
                    </Col>
                    <Col
                      sm={12}
                      xl={10}
                      className={`${styles.content_container}`}
                    >
                      <h4 className="subheading_text white_text">
                        {value.title}
                      </h4>
                      <p className="paragraph_text light_grey_text">
                        {value.subtitle}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Container className="extra_sides_padding">
          <Row className={`section_vertical_spacing`}>
            <Col xs={12} className={`${styles.what_section}`}>
              <div className={`${styles.what_section_text}`}>
                <h1 className="heading_text primary_orange_text">
                  What's the Fam like?
                </h1>
                <Row>
                  {famWorkFeatures.map((workFeature, key) => (
                    <Col
                      xs={12}
                      key={key}
                      className={`${styles.growth_section}`}
                    >
                      <Row className={styles.growth_item_container}>
                        <Col sm={3}>
                          <img src={workFeature.icon} alt="" />
                        </Col>
                        <Col sm={9}>
                          <h6 className="subheading_text white_text">
                            {workFeature.title}
                          </h6>
                          <p className="paragraph_text light_grey_text">
                            {workFeature.subtitle}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
          <Row className={`section_vertical_spacing`}>
            <Col xs={12}>
              <hr
                className="dotted_separator"
                style={{ borderTop: "1px dashed #a1a1a1" }}
              />
              <Row className={`${styles.social_section}`}>
                <Col xs={12}>
                  <p className="paragraph_text light_grey_text text-center">
                    We like to keep things social. Take a look!
                  </p>
                </Col>
                <Col
                  xs={12}
                  className={`paragraph_text ${styles.social_icons_container}`}
                >
                  <a
                    className={styles.icon_btn}
                    href="https://www.instagram.com/fam.india/"
                    target="__blank"
                  >
                    <span>
                      <img
                        alt="Instagram"
                        src={instagramColorIcon}
                        className="mb-1"
                      />
                      fam.india
                    </span>
                  </a>
                  <a
                    className={styles.icon_btn}
                    href="https://www.instagram.com/lifeatfam/"
                    target="__blank"
                  >
                    <span>
                      <img
                        alt="Instagram"
                        src={instagramColorIcon}
                        className="mb-2"
                      />
                      Life at Fam
                    </span>
                  </a>
                  <a
                    className={styles.icon_btn}
                    href="https://www.instagram.com/designatfam/"
                    target="__blank"
                  >
                    <span>
                      <img
                        alt="Instagram"
                        src={instagramColorIcon}
                        className="mb-2"
                      />
                      Design at Fam
                    </span>
                  </a>
                  <a
                    className={styles.icon_btn}
                    href="https://twitter.com/famappbytrio"
                    target="__blank"
                  >
                    <span>
                      <img alt="Twitter" src={twitterColorIcon} />
                      @yougotfam
                    </span>
                  </a>
                  <a
                    className={styles.icon_btn}
                    href="https://www.linkedin.com/company/fampay/"
                    target="__blank"
                  >
                    <span>
                      <img
                        alt="LinkedIn"
                        src={linkedinColorIcon}
                        className="mb-2"
                      />
                      Fam India
                    </span>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row
          className={`full_width_section ${styles.perks_section} light_grey_text`}
        >
          <Col>
            <Row className="text-center">
              <Col xs={12} className={`${styles.section_heading}`}>
                <h1 className="heading_text primary_orange_text">
                  Warning: our perks might make your friends mad 👀
                </h1>
              </Col>
              <Col xs={12} className={`${styles.perks_container}`}>
                <div className={styles.perks_item_container}>
                  {Perks.map((item, key) => (
                    <Col md={6} lg={4} className={styles.perks_item} key={key}>
                      <img src={item.img} alt="" />
                      <p className="light_grey_text paragraph_text">
                        {item.title}
                      </p>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={`text-center`}>
                <br />
                <p className={`paragraph_text light_grey_text`}>
                  All this, along with a generous leave policy,
                  <br />
                  experienced mentorship, and more.
                </p>
                <ButtonContainer
                  buttonText={"View openings"}
                  path="jobs"
                  activeStatus
                  wrapStyle={{
                    backgroundColor: "#f5a623",
                    color: "#000000",
                    marginTop: "1.25rem",
                    fontWeight: "600",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer dark />
      </Container>
    </>
  );
};

export default Careers;
