/* eslint-disable no-undef */
const NEW_BASE = "/assets/images/images";

// Home
export const qrcode = `${NEW_BASE}/pages/index/qrcode.png`;
export const upiImage = `${NEW_BASE}/pages/index/upiImage.svg`;
export const scanPayUpi = `${NEW_BASE}/pages/index/scanPayUpi.svg`;
export const personalizedThemes = `${NEW_BASE}/pages/index/personalizedThemes.svg`;
export const recharge = `${NEW_BASE}/pages/index/recharge.svg`;
export const hiringImage = `${NEW_BASE}/pages/index/hiringImage.png`;
export const supportChatGif = `${NEW_BASE}/pages/index/supportChatGif.gif`;
export const HomeScreenPCVisual = `${NEW_BASE}/pages/index/HomeScreenPCVisual.mp4`;
export const HomeScreenMobVisual = `${NEW_BASE}/pages/index/HomeScreenMobVisual.mp4`;
export const SpendingAccountVisual = `${NEW_BASE}/pages/index/SpendingAccountVisual.mp4`;
export const SpendingAccountImage = `${NEW_BASE}/pages/index/SpendingAccountImage.jpg`;
export const userTrustImage = `${NEW_BASE}/pages/index/userTrustImage.png`;
export const googlePlayImage = `${NEW_BASE}/pages/index/googlePlayImage.png`;
export const appleStoreImage = `${NEW_BASE}/pages/index/appleStoreImage.png`;
export const elevationImage = `${NEW_BASE}/pages/index/elevationImage.png`;
export const sequoiaImage = `${NEW_BASE}/pages/index/sequoiaImage.png`;
export const vhImage = `${NEW_BASE}/pages/index/vhImage.png`;
export const gcImage = `${NEW_BASE}/pages/index/gcImage.png`;
export const ycImage = `${NEW_BASE}/pages/index/ycImage.png`;
export const gfcImage = `${NEW_BASE}/pages/index/gfcImage.png`;
export const rocketshipImage = `${NEW_BASE}/pages/index/rocketshipImage.png`;
export const greenoaksImage = `${NEW_BASE}/pages/index/greenoaksImage.png`;
export const burnFamcoinImage = `${NEW_BASE}/pages/index/burnFamcoinImage.jpg`;
export const earnFamcoinImage = `${NEW_BASE}/pages/index/earnFamcoinImage.jpg`;
export const burnFamcoinPhoneImage = `${NEW_BASE}/pages/index/burnFamcoinPhoneImage.png`;
export const rewardIcon = `${NEW_BASE}/pages/index/rewardIcon.png`;
export const giveaway = `${NEW_BASE}/pages/index/giveaway.png`;
export const challenges = `${NEW_BASE}/pages/index/challenges.png`;
export const earnFamcoinPhoneImage = `${NEW_BASE}/pages/index/earnFamcoinPhoneImage.png`;
export const famcoinsStripVisual = `${NEW_BASE}/pages/index/famcoinsStripVisual.mp4`;
export const famcoinRewards = `${NEW_BASE}/pages/index/famcoinRewards.png`;
export const FamXBanner = `${NEW_BASE}/pages/index/FamXBanner.png`;
export const TrioLogo = `${NEW_BASE}/pages/index/TrioLogo.png`;
export const VisaLogo = `${NEW_BASE}/pages/index/VisaLogo.png`;
export const RupayLogo = `${NEW_BASE}/pages/index/RupayLogo.png`;
export const UPILogo = `${NEW_BASE}/pages/index/UPILogo.png`;
export const FamXTeens = `${NEW_BASE}/pages/index/FamXTeens.png`;
export const FamXCardTitle = `${NEW_BASE}/pages/index/FamXCardTitle.png`;
export const FamXCard = `${NEW_BASE}/pages/index/FamXCard.png`;
export const Classic = `${NEW_BASE}/pages/index/Classic.png`;
export const HigherDepositLimits = `${NEW_BASE}/pages/index/HigherDepositLimits.png`;
export const Ultra = `${NEW_BASE}/pages/index/Ultra.png`;

// Careers
export const famWork1Image = `${NEW_BASE}/pages/careers/famWork1Image.png`;
export const famWork2Image = `${NEW_BASE}/pages/careers/famWork2Image.png`;
export const famWork3Image = `${NEW_BASE}/pages/careers/famWork3Image.png`;
export const famWork4Image = `${NEW_BASE}/pages/careers/famWork4Image.png`;
export const famPerk1Image = `${NEW_BASE}/pages/careers/famPerk1Image.jpg`;
export const famPerk2Image = `${NEW_BASE}/pages/careers/famPerk2Image.jpg`;
export const famPerk3Image = `${NEW_BASE}/pages/careers/famPerk3Image.jpg`;
export const famPerk4Image = `${NEW_BASE}/pages/careers/famPerk4Image.jpg`;
export const famPerk5Image = `${NEW_BASE}/pages/careers/famPerk5Image.jpg`;
export const famPerk6Image = `${NEW_BASE}/pages/careers/famPerk6Image.jpg`;
export const gadgetsImage = `${NEW_BASE}/pages/careers/gadgetsImage.png`;
export const memory1Image = `${NEW_BASE}/pages/careers/memory1Image.jpg`;
export const memory2Image = `${NEW_BASE}/pages/careers/memory2Image.jpg`;
export const memory3Image = `${NEW_BASE}/pages/careers/memory3Image.jpg`;
export const memory4Image = `${NEW_BASE}/pages/careers/memory4Image.jpg`;
export const memory5Image = `${NEW_BASE}/pages/careers/memory5Image.jpg`;
export const memory6Image = `${NEW_BASE}/pages/careers/memory6Image.jpg`;
export const memory7Image = `${NEW_BASE}/pages/careers/memory7Image.jpeg`;
export const careersPCHeaderImage = `${NEW_BASE}/pages/careers/careersPCHeaderImage.jpg`;
export const careersMobHeaderImage = `${NEW_BASE}/pages/careers/careersMobHeaderImage.jpg`;

// About
export const aboutMobHeaderImage = `${NEW_BASE}/pages/about/aboutMobHeaderImage.jpg`;
export const aboutPCHeaderImage = `${NEW_BASE}/pages/about/aboutPCHeaderImage.jpg`;
export const missionImage = `${NEW_BASE}/pages/about/missionImage.jpg`;
export const storyImage = `${NEW_BASE}/pages/about/storyImage.jpg`;
export const famvillaImage = `${NEW_BASE}/pages/about/famvillaImage.jpg`;
export const openingsImage = `${NEW_BASE}/pages/about/openingsImage.jpeg`;

// Contact
export const contactPCHeaderImage = `${NEW_BASE}/pages/contact/contactPCHeaderImage.png`;
export const contactMobHeaderImage = `${NEW_BASE}/pages/contact/contactMobHeaderImage.png`;
export const callUs = `${NEW_BASE}/pages/contact/callUs.png`;
export const emailUs = `${NEW_BASE}/pages/contact/emailUs.png`;
export const ourInstagram = `${NEW_BASE}/pages/contact/ourInstagram.png`;
export const ourTwitter = `${NEW_BASE}/pages/contact/ourTwitter.png`;
export const arrowUpRight = `${NEW_BASE}/pages/contact/arrowUpRight.svg`;

// Testimonials
export const anairaImage = `${NEW_BASE}/common/testimonials/anairaImage.jpg`;
export const divyanshImage = `${NEW_BASE}/common/testimonials/divyanshImage.jpg`;
export const janviImage = `${NEW_BASE}/common/testimonials/janviImage.jpg`;
export const jyotirImage = `${NEW_BASE}/common/testimonials/jyotirImage.jpg`;
export const karanImage = `${NEW_BASE}/common/testimonials/karanImage.jpg`;
export const neetaImage = `${NEW_BASE}/common/testimonials/neetaImage.jpg`;
export const pankajImage = `${NEW_BASE}/common/testimonials/pankajImage.jpg`;
export const rajinderImage = `${NEW_BASE}/common/testimonials/rajinderImage.jpg`;
export const suhaniImage = `${NEW_BASE}/common/testimonials/suhaniImage.png`;
export const AdityaImg = `${NEW_BASE}/common/testimonials/AdityaImg.jpg`;
export const CassiusImg = `${NEW_BASE}/common/testimonials/CassiusImg.jpg`;
export const EshaaImg = `${NEW_BASE}/common/testimonials/EshaaImg.jpg`;
// export const suhaniImage = `${NEW_BASE}/common/testimonials/suhaniImage.png`;

// Team Members (TBC - remove them completely or move
// from S3 depending on if we are keeping them in next iteration)
const CDN_URL = "https://cdn.famapp.co.in/prod";
const BASE_IMG_URL = `${CDN_URL}/website`;

const BASE_TEAM_URL = `${BASE_IMG_URL}/famstars`;
export const Aapram = `${BASE_TEAM_URL}/Aapram.png`;
export const Abhay = `${BASE_TEAM_URL}/abhay.png`;
export const Aditi = `${BASE_TEAM_URL}/aditi.png`;
export const Aditya = `${BASE_TEAM_URL}/Aditya.png`;
export const Akash = `${BASE_TEAM_URL}/akash.png`;
export const Akhil = `${BASE_TEAM_URL}/Akhil.png`;
export const Anchit = `${BASE_TEAM_URL}/anchit.png`;
export const Aayush = `${BASE_TEAM_URL}/aayush.png`;
export const Bharath = `${BASE_TEAM_URL}/bharath.png`;
export const Charvi = `${BASE_TEAM_URL}/Charvi.png`;
export const Chirag = `${BASE_TEAM_URL}/chirag.png`;
export const Chitra = `${BASE_TEAM_URL}/chitra.png`;
export const Diya = `${BASE_TEAM_URL}/diya.png`;
export const Elisha = `${BASE_TEAM_URL}/elisha.png`;
export const Harjot = `${BASE_TEAM_URL}/harjot.png`;
export const Hrishi = `${BASE_TEAM_URL}/Hrishi.png`;
export const Janani = `${BASE_TEAM_URL}/janani.png`;
export const Jithu = `${BASE_TEAM_URL}/jitendra.png`;
export const KT = `${BASE_TEAM_URL}/kt.png`;
export const Pratik = `${BASE_TEAM_URL}/pratikG.png`;
export const Prem = `${BASE_TEAM_URL}/prem.png`;
export const Ritwik = `${BASE_TEAM_URL}/ritwick.png`;
export const Sajal = `${BASE_TEAM_URL}/sajal.png`;
export const Sambhav = `${BASE_TEAM_URL}/sambhav.png`;
export const Sanskar = `${BASE_TEAM_URL}/sanskar.png`;
export const Saransh = `${BASE_TEAM_URL}/saransh.png`;
export const SaurabhG = `${BASE_TEAM_URL}/saurabhG.png`;
export const Shagun = `${BASE_TEAM_URL}/shagun.png`;
export const Shifa = `${BASE_TEAM_URL}/shifa.png`;
export const Supriya = `${BASE_TEAM_URL}/supriya.png`;
export const Tanisha = `${BASE_TEAM_URL}/tanisha.png`;
export const Tanya = `${BASE_TEAM_URL}/tanya.png`;
export const Tiara = `${BASE_TEAM_URL}/tiara.png`;
export const Vardha = `${BASE_TEAM_URL}/vardha.png`;
export const Vicky = `${BASE_TEAM_URL}/vikranth.png`;
export const Vishnu = `${BASE_TEAM_URL}/vishnu.png`;
export const AadeshUikey = `${BASE_TEAM_URL}/AadeshUikey.webp`;
export const AbhishekDas = `${BASE_TEAM_URL}/AbhishekDas.webp`;
export const AbhishekMore = `${BASE_TEAM_URL}/AbhishekMore.webp`;
export const AishwaryaNayak = `${BASE_TEAM_URL}/AishwaryaNayak.webp`;
export const AkschayaBalaji = `${BASE_TEAM_URL}/AkschayaBalaji.webp`;
export const AmarenderSingh = `${BASE_TEAM_URL}/AmarenderSingh.webp`;
export const AneeshPanda = `${BASE_TEAM_URL}/AneeshPanda.webp`;
export const DevanshAggarwal = `${BASE_TEAM_URL}/DevanshAggarwal.webp`;
export const FaizanHaider = `${BASE_TEAM_URL}/FaizanHaider.webp`;
export const KadambariChikara = `${BASE_TEAM_URL}/KadambariChikara.webp`;
export const KshitijaDeshpande = `${BASE_TEAM_URL}/KshitijaDeshpande.webp`;
export const LishaKothari = `${BASE_TEAM_URL}/LishaKothari.webp`;
export const MoinakBanerjee = `${BASE_TEAM_URL}/MoinakBanerjee.webp`;
export const MonicaSatyakumar = `${BASE_TEAM_URL}/MonicaSatyakumar.webp`;
export const NamanNarula = `${BASE_TEAM_URL}/NamanNarula.webp`;
export const OsheenMahant = `${BASE_TEAM_URL}/OsheenMahant.webp`;
export const PiyushDagdiya = `${BASE_TEAM_URL}/PiyushDagdiya.webp`;
export const PranavJain = `${BASE_TEAM_URL}/PranavJain.webp`;
export const PriyaSarsaiya = `${BASE_TEAM_URL}/PriyaSarsaiya.webp`;
export const RaghavSharma = `${BASE_TEAM_URL}/RaghavSharma.webp`;
export const RajPandey = `${BASE_TEAM_URL}/RajPandey.webp`;
export const RaunakJalan = `${BASE_TEAM_URL}/RaunakJalan.webp`;
export const RichaKhandelwal = `${BASE_TEAM_URL}/RichaKhandelwal.webp`;
export const RohitKuber = `${BASE_TEAM_URL}/RohitKuber.webp`;
export const SamriddhiAgrawal = `${BASE_TEAM_URL}/SamriddhiAgrawal.webp`;
export const SandeepSingh = `${BASE_TEAM_URL}/SandeepSingh.webp`;
export const SandeepSrinivasan = `${BASE_TEAM_URL}/SandeepSrinivasan.webp`;
export const SangamChabbra = `${BASE_TEAM_URL}/SangamChabbra.webp`;
export const ShlokPatel = `${BASE_TEAM_URL}/ShlokPatel.webp`;
export const SiddharthSen = `${BASE_TEAM_URL}/SiddharthSen.webp`;
export const SoutikKumar = `${BASE_TEAM_URL}/SoutikKumar.webp`;
export const SrinidhiDG = `${BASE_TEAM_URL}/SrinidhiDG.webp`;
export const SunilKumar = `${BASE_TEAM_URL}/SunilKumar.webp`;
export const TejasRajagopal = `${BASE_TEAM_URL}/TejasRajagopal.webp`;
export const YashVerma = `${BASE_TEAM_URL}/YashVerma.webp`;
export const MuazzZuberi = `${BASE_TEAM_URL}/MuazzZuberi.webp`;

const BASE_TEAM_URL_NEW = `${NEW_BASE}/famstars`;

export const hema = `${BASE_TEAM_URL_NEW}/hema.png`;
export const sid = `${BASE_TEAM_URL_NEW}/sid.png`;
export const melron = `${BASE_TEAM_URL_NEW}/melron.png`;
export const ananya = `${BASE_TEAM_URL_NEW}/ananya.png`;
export const karthik = `${BASE_TEAM_URL_NEW}/karthik.png`;
export const abhiTyagi = `${BASE_TEAM_URL_NEW}/abhiTyagi.png`;
export const anand = `${BASE_TEAM_URL_NEW}/anand.png`;
export const vaibhavi = `${BASE_TEAM_URL_NEW}/vaibhavi.png`;
export const aditiSalke = `${BASE_TEAM_URL_NEW}/aditiSalke.png`;
export const agniva = `${BASE_TEAM_URL_NEW}/agniva.png`;
export const archit = `${BASE_TEAM_URL_NEW}/archit.png`;
export const devarsh = `${BASE_TEAM_URL_NEW}/devarsh.png`;
export const arohi = `${BASE_TEAM_URL_NEW}/arohi.png`;
export const priyanka = `${BASE_TEAM_URL_NEW}/priyanka.png`;
export const ajith = `${BASE_TEAM_URL_NEW}/ajith.png`;
export const charviGowda = `${BASE_TEAM_URL_NEW}/charviGowda.png`;
export const anshu = `${BASE_TEAM_URL_NEW}/anshu.png`;
export const supriyaBhandari = `${BASE_TEAM_URL_NEW}/supriya.png`;
export const yashJain = `${BASE_TEAM_URL_NEW}/yashJain.png`;
export const gaurav = `${BASE_TEAM_URL_NEW}/gaurav.png`;
export const komal = `${BASE_TEAM_URL_NEW}/komal.png`;
export const mohit = `${BASE_TEAM_URL_NEW}/mohit.png`;
export const nimitt = `${BASE_TEAM_URL_NEW}/nimitt.png`;
export const sameeran = `${BASE_TEAM_URL_NEW}/sameeran.png`;
export const shikhar = `${BASE_TEAM_URL_NEW}/shikhar.png`;
export const soumik = `${BASE_TEAM_URL_NEW}/soumik.png`;
export const sumit = `${BASE_TEAM_URL_NEW}/sumit.png`;
export const shubhangi = `${BASE_TEAM_URL_NEW}/shubhangi.png`;
export const brijesh = `${BASE_TEAM_URL_NEW}/brijesh.png`;
export const vishalJain = `${BASE_TEAM_URL_NEW}/vishalJain.png`;
export const vishal = `${BASE_TEAM_URL_NEW}/vishal.png`;
export const aishwarya = `${BASE_TEAM_URL_NEW}/aishwarya.png`;
export const garvita = `${BASE_TEAM_URL_NEW}/garvita.png`;
export const ridima = `${BASE_TEAM_URL_NEW}/ridima.png`;
export const sanjeet = `${BASE_TEAM_URL_NEW}/sanjeet.png`;
export const shashank = `${BASE_TEAM_URL_NEW}/shashank.png`;
export const moksha = `${BASE_TEAM_URL_NEW}/moksha.png`;
export const prasanjeet = `${BASE_TEAM_URL_NEW}/prasanjeet.png`;
export const manasvi = `${BASE_TEAM_URL_NEW}/manasvi.png`;
export const sakshi = `${BASE_TEAM_URL_NEW}/sakshi.png`;
export const sabarish = `${BASE_TEAM_URL_NEW}/sabarish.png`;
export const karan = `${BASE_TEAM_URL_NEW}/karan.png`;
export const peeyush = `${BASE_TEAM_URL_NEW}/peeyush.png`;
export const lapekshi = `${BASE_TEAM_URL_NEW}/lapekshi.png`;
export const shobhit = `${BASE_TEAM_URL_NEW}/shobhit.png`;
export const rohini = `${BASE_TEAM_URL_NEW}/rohini.png`;
export const sanat = `${BASE_TEAM_URL_NEW}/sanat.png`;
export const RadhikaBhutada = `${BASE_TEAM_URL_NEW}/RadhikaBhutada.webp`;
export const PrakashYogi = `${BASE_TEAM_URL_NEW}/PrakashYogi.webp`;
export const SibasishMohanty = `${BASE_TEAM_URL_NEW}/SibasishMohanty.webp`;
export const BanutejaBojja = `${BASE_TEAM_URL_NEW}/BanutejaB.webp`;
export const BhaskarRaj = `${BASE_TEAM_URL_NEW}/BhaskarRaj.webp`;
export const MayankSheoran = `${BASE_TEAM_URL_NEW}/MayankSheoran.webp`;
export const AtharvaKinikar = `${BASE_TEAM_URL_NEW}/AtharvaKinikar.webp`;
export const AyushTiwari = `${BASE_TEAM_URL_NEW}/AyushTiwari.webp`;
export const ShouryaDe = `${BASE_TEAM_URL_NEW}/ShouryaDe.webp`;
export const ArnavSinghal = `${BASE_TEAM_URL_NEW}/ArnavSinghal.webp`;
export const PrathamMishra = `${BASE_TEAM_URL_NEW}/PrathamMishra.webp`;
export const AnahitaIyer = `${BASE_TEAM_URL_NEW}/AnahitaIyer.webp`;
export const VedantMatanhelia = `${BASE_TEAM_URL_NEW}/VedantMatanhelia.webp`;
export const VedantDaigavane = `${BASE_TEAM_URL_NEW}/VedantDaigavane.webp`;
export const SiddharthShivdasani = `${BASE_TEAM_URL_NEW}/SiddharthShivdasani.webp`;

// JOBS
export const fampayLogo = `${NEW_BASE}/pages/jobs/fampayLogo.png`;
export const ArrowHead = `${NEW_BASE}/pages/jobs/arrowHead.svg`;
export const loader = `${NEW_BASE}/pages/jobs/loader.gif`;
